<template>
  <CContainer class="d-flex align-items-center min-vh-100  col-md-12">
    <CRow class="justify-content-center col-md-12">
      <CCard
        color="white"
        border-color="light"
        text-color="dark"
        class="text-center col-md-3"
        body-wrapper
      >
        <CAlert v-if="alert != ''" color="danger">{{ alert }}</CAlert>
        <img
          class="pt-5"
          src="https://one.th/img/logo_one platform for light background.png"
          width="200"
          alt="alt"
        />
        <br />
        <br />
        <CForm>
          <br />
          <CInput v-model="username" type="text" placeholder="ชื่อผู้ใช้งาน">
            <template #prepend-content>
              <CIcon name="cil-user" />
            </template>
          </CInput>
          <CInput v-model="password" type="password" placeholder="รหัสผ่าน">
            <template #prepend-content>
              <CIcon name="cil-lock-locked" />
            </template>
          </CInput>

          <CRow>
            <CCol col="12" v-if="loadingButton === true" class="pt-3">
              <CButton @click="login" block color="success" class="px-4">
                ลงชื่อเข้าใช้
              </CButton>
            </CCol>
            <CCol col="12" v-else-if="loadingButton === false" class="pt-3">
              <CButton block color="success" disabled>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
            <CCol col="12" class="text-center pt-1">
              <a
                href="https://one.th/type_forgot_password"
                style="text-decoration: none;"
              >
                ลืมรหัสผ่าน ?
              </a>
              <hr />
              <p>คุณยังไม่มีบัญชี One Platform ใช่หรือไม่?</p>
              <CButton
                href="https://one.th/register"
                block
                color="dark"
                class="px-2"
                >ลงทะเบียน One Service</CButton
              >
            </CCol>
          </CRow>
        </CForm>
      </CCard>
    </CRow>
  </CContainer>
</template>

<script>
import axios from '../../services/auth'

export default {
  data() {
    return {
      username: '',
      password: '',
      alert: '',
      loadingButton: true,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'ONE PLATFORM'
      },
    },
  },
  methods: {
    login() {
      this.loadingButton = false

      axios
        .post('/auth/v1.0/loginWithOneID', {
          username: this.username,
          password: this.password,
          returnSecureToken: true,
        })
        .then((res) => {
          if (res.data.error.code != 0) {
            this.alert = res.data.error.message
            this.loadingButton = true
          }

          if (res.data.data.uid != undefined) {
            this.data = res.data.data
            localStorage.setItem('uid', this.data.uid)
            localStorage.setItem('customToken', this.data.customToken)
            localStorage.setItem('loginwith', 'one-id')

            this.$store.dispatch('loginWithCustomToken')
          }

          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
